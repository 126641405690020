
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexOIb2ogR8qrvv8XK1qEXuo_vyO3EXDYaHjTUliVHxJX4Meta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/index.vue?macro=true";
import { default as indexTf2xQTgtNxgc5e8cf0wNfhEx7QZ0W_XQs7E_UcoysvAMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as start2jVYUh5ax06VHGgXi_45hFPaoZWr_45x8UCV6lVSCtWDRwUMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startoK2YiBMYXX_45jJRKuCIFooIBHjVCKXWWWk_Dzm94d6XwMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkout3YwmNcBzgRRSUs2gDljvJ2Xi_FOpVkIhCbUT6e8BsT8Meta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koOcXB4_DitZj525K5YL5X_GghjO9S41_p1nB01WGMVeMMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationCVbUmn5FeRgzeJHfr90LjwTo7g6jBquKAE_EqHTTnb0Meta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationVKFAJO8hTF6HdlYsey04BSCNAtCZkm2reMhDXV2NpFkMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyercjEc2JtpNDZLaOf8x9r7ba6HvszOZa_oH_hfSws1imwMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerQAgZuELg7XV6tnueQ1IkhK20ol4Rtkxj8r0r9mriErYMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentW4G9uooTtGraYLLp3ah59LXZuLF3MSZN_45FGmtfb7l5YMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengesn4HV1NvwAuzfAGf2LyDWUhAW0ZLwCmdj63puKa4ji8Meta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentmmLbHXE0_g5N_RDGcnGNrH9uJXeFjvHAza4xfTSt9zUMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta } from "/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexOIb2ogR8qrvv8XK1qEXuo_vyO3EXDYaHjTUliVHxJX4Meta?.name ?? "index",
    path: indexOIb2ogR8qrvv8XK1qEXuo_vyO3EXDYaHjTUliVHxJX4Meta?.path ?? "/",
    props: indexOIb2ogR8qrvv8XK1qEXuo_vyO3EXDYaHjTUliVHxJX4Meta?.props ?? false,
    meta: indexOIb2ogR8qrvv8XK1qEXuo_vyO3EXDYaHjTUliVHxJX4Meta || {},
    alias: indexOIb2ogR8qrvv8XK1qEXuo_vyO3EXDYaHjTUliVHxJX4Meta?.alias || [],
    redirect: indexOIb2ogR8qrvv8XK1qEXuo_vyO3EXDYaHjTUliVHxJX4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/index.vue")
  },
  {
    name: indexTf2xQTgtNxgc5e8cf0wNfhEx7QZ0W_XQs7E_UcoysvAMeta?.name ?? "lang",
    path: indexTf2xQTgtNxgc5e8cf0wNfhEx7QZ0W_XQs7E_UcoysvAMeta?.path ?? "/:lang?",
    props: indexTf2xQTgtNxgc5e8cf0wNfhEx7QZ0W_XQs7E_UcoysvAMeta?.props ?? false,
    meta: indexTf2xQTgtNxgc5e8cf0wNfhEx7QZ0W_XQs7E_UcoysvAMeta || {},
    alias: indexTf2xQTgtNxgc5e8cf0wNfhEx7QZ0W_XQs7E_UcoysvAMeta?.alias || [],
    redirect: indexTf2xQTgtNxgc5e8cf0wNfhEx7QZ0W_XQs7E_UcoysvAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: start2jVYUh5ax06VHGgXi_45hFPaoZWr_45x8UCV6lVSCtWDRwUMeta?.name ?? "lang-voucher-start",
    path: start2jVYUh5ax06VHGgXi_45hFPaoZWr_45x8UCV6lVSCtWDRwUMeta?.path ?? "/:lang?/voucher/start",
    props: start2jVYUh5ax06VHGgXi_45hFPaoZWr_45x8UCV6lVSCtWDRwUMeta?.props ?? false,
    meta: start2jVYUh5ax06VHGgXi_45hFPaoZWr_45x8UCV6lVSCtWDRwUMeta || {},
    alias: start2jVYUh5ax06VHGgXi_45hFPaoZWr_45x8UCV6lVSCtWDRwUMeta?.alias || [],
    redirect: start2jVYUh5ax06VHGgXi_45hFPaoZWr_45x8UCV6lVSCtWDRwUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startoK2YiBMYXX_45jJRKuCIFooIBHjVCKXWWWk_Dzm94d6XwMeta?.name ?? "lang-purchase-start",
    path: startoK2YiBMYXX_45jJRKuCIFooIBHjVCKXWWWk_Dzm94d6XwMeta?.path ?? "/:lang?/purchase/start",
    props: startoK2YiBMYXX_45jJRKuCIFooIBHjVCKXWWWk_Dzm94d6XwMeta?.props ?? false,
    meta: startoK2YiBMYXX_45jJRKuCIFooIBHjVCKXWWWk_Dzm94d6XwMeta || {},
    alias: startoK2YiBMYXX_45jJRKuCIFooIBHjVCKXWWWk_Dzm94d6XwMeta?.alias || [],
    redirect: startoK2YiBMYXX_45jJRKuCIFooIBHjVCKXWWWk_Dzm94d6XwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkout3YwmNcBzgRRSUs2gDljvJ2Xi_FOpVkIhCbUT6e8BsT8Meta?.name ?? "lang-service-booking-checkout",
    path: checkout3YwmNcBzgRRSUs2gDljvJ2Xi_FOpVkIhCbUT6e8BsT8Meta?.path ?? "/:lang?/service-booking/checkout",
    props: checkout3YwmNcBzgRRSUs2gDljvJ2Xi_FOpVkIhCbUT6e8BsT8Meta?.props ?? false,
    meta: checkout3YwmNcBzgRRSUs2gDljvJ2Xi_FOpVkIhCbUT6e8BsT8Meta || {},
    alias: checkout3YwmNcBzgRRSUs2gDljvJ2Xi_FOpVkIhCbUT6e8BsT8Meta?.alias || [],
    redirect: checkout3YwmNcBzgRRSUs2gDljvJ2Xi_FOpVkIhCbUT6e8BsT8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koOcXB4_DitZj525K5YL5X_GghjO9S41_p1nB01WGMVeMMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koOcXB4_DitZj525K5YL5X_GghjO9S41_p1nB01WGMVeMMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koOcXB4_DitZj525K5YL5X_GghjO9S41_p1nB01WGMVeMMeta?.props ?? false,
    meta: koOcXB4_DitZj525K5YL5X_GghjO9S41_p1nB01WGMVeMMeta || {},
    alias: koOcXB4_DitZj525K5YL5X_GghjO9S41_p1nB01WGMVeMMeta?.alias || [],
    redirect: koOcXB4_DitZj525K5YL5X_GghjO9S41_p1nB01WGMVeMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationCVbUmn5FeRgzeJHfr90LjwTo7g6jBquKAE_EqHTTnb0Meta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationCVbUmn5FeRgzeJHfr90LjwTo7g6jBquKAE_EqHTTnb0Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationCVbUmn5FeRgzeJHfr90LjwTo7g6jBquKAE_EqHTTnb0Meta?.props ?? false,
    meta: confirmationCVbUmn5FeRgzeJHfr90LjwTo7g6jBquKAE_EqHTTnb0Meta || {},
    alias: confirmationCVbUmn5FeRgzeJHfr90LjwTo7g6jBquKAE_EqHTTnb0Meta?.alias || [],
    redirect: confirmationCVbUmn5FeRgzeJHfr90LjwTo7g6jBquKAE_EqHTTnb0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationVKFAJO8hTF6HdlYsey04BSCNAtCZkm2reMhDXV2NpFkMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationVKFAJO8hTF6HdlYsey04BSCNAtCZkm2reMhDXV2NpFkMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationVKFAJO8hTF6HdlYsey04BSCNAtCZkm2reMhDXV2NpFkMeta?.props ?? false,
    meta: confirmationVKFAJO8hTF6HdlYsey04BSCNAtCZkm2reMhDXV2NpFkMeta || {},
    alias: confirmationVKFAJO8hTF6HdlYsey04BSCNAtCZkm2reMhDXV2NpFkMeta?.alias || [],
    redirect: confirmationVKFAJO8hTF6HdlYsey04BSCNAtCZkm2reMhDXV2NpFkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyercjEc2JtpNDZLaOf8x9r7ba6HvszOZa_oH_hfSws1imwMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyercjEc2JtpNDZLaOf8x9r7ba6HvszOZa_oH_hfSws1imwMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyercjEc2JtpNDZLaOf8x9r7ba6HvszOZa_oH_hfSws1imwMeta?.props ?? false,
    meta: buyercjEc2JtpNDZLaOf8x9r7ba6HvszOZa_oH_hfSws1imwMeta || {},
    alias: buyercjEc2JtpNDZLaOf8x9r7ba6HvszOZa_oH_hfSws1imwMeta?.alias || [],
    redirect: buyercjEc2JtpNDZLaOf8x9r7ba6HvszOZa_oH_hfSws1imwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerQAgZuELg7XV6tnueQ1IkhK20ol4Rtkxj8r0r9mriErYMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerQAgZuELg7XV6tnueQ1IkhK20ol4Rtkxj8r0r9mriErYMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerQAgZuELg7XV6tnueQ1IkhK20ol4Rtkxj8r0r9mriErYMeta?.props ?? false,
    meta: buyerQAgZuELg7XV6tnueQ1IkhK20ol4Rtkxj8r0r9mriErYMeta || {},
    alias: buyerQAgZuELg7XV6tnueQ1IkhK20ol4Rtkxj8r0r9mriErYMeta?.alias || [],
    redirect: buyerQAgZuELg7XV6tnueQ1IkhK20ol4Rtkxj8r0r9mriErYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentW4G9uooTtGraYLLp3ah59LXZuLF3MSZN_45FGmtfb7l5YMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentW4G9uooTtGraYLLp3ah59LXZuLF3MSZN_45FGmtfb7l5YMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentW4G9uooTtGraYLLp3ah59LXZuLF3MSZN_45FGmtfb7l5YMeta?.props ?? false,
    meta: paymentW4G9uooTtGraYLLp3ah59LXZuLF3MSZN_45FGmtfb7l5YMeta || {},
    alias: paymentW4G9uooTtGraYLLp3ah59LXZuLF3MSZN_45FGmtfb7l5YMeta?.alias || [],
    redirect: paymentW4G9uooTtGraYLLp3ah59LXZuLF3MSZN_45FGmtfb7l5YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengesn4HV1NvwAuzfAGf2LyDWUhAW0ZLwCmdj63puKa4ji8Meta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengesn4HV1NvwAuzfAGf2LyDWUhAW0ZLwCmdj63puKa4ji8Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengesn4HV1NvwAuzfAGf2LyDWUhAW0ZLwCmdj63puKa4ji8Meta?.props ?? false,
    meta: challengesn4HV1NvwAuzfAGf2LyDWUhAW0ZLwCmdj63puKa4ji8Meta || {},
    alias: challengesn4HV1NvwAuzfAGf2LyDWUhAW0ZLwCmdj63puKa4ji8Meta?.alias || [],
    redirect: challengesn4HV1NvwAuzfAGf2LyDWUhAW0ZLwCmdj63puKa4ji8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentmmLbHXE0_g5N_RDGcnGNrH9uJXeFjvHAza4xfTSt9zUMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentmmLbHXE0_g5N_RDGcnGNrH9uJXeFjvHAza4xfTSt9zUMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentmmLbHXE0_g5N_RDGcnGNrH9uJXeFjvHAza4xfTSt9zUMeta?.props ?? false,
    meta: paymentmmLbHXE0_g5N_RDGcnGNrH9uJXeFjvHAza4xfTSt9zUMeta || {},
    alias: paymentmmLbHXE0_g5N_RDGcnGNrH9uJXeFjvHAza4xfTSt9zUMeta?.alias || [],
    redirect: paymentmmLbHXE0_g5N_RDGcnGNrH9uJXeFjvHAza4xfTSt9zUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.path ?? "/pt/spas/",
    props: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.props ?? false,
    meta: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta || {},
    alias: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.alias || [],
    redirect: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.path ?? "/pt/spas/gran-canaria/",
    props: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.props ?? false,
    meta: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta || {},
    alias: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.alias || [],
    redirect: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.path ?? "/pt/spas/gran-canaria/agaete/",
    props: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.props ?? false,
    meta: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta || {},
    alias: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.alias || [],
    redirect: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.path ?? "/pt/spas/gran-canaria/las-palmas-de-gran-canaria/",
    props: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.props ?? false,
    meta: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta || {},
    alias: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.alias || [],
    redirect: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.path ?? "/pt/spas/gran-canaria/maspalomas/",
    props: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.props ?? false,
    meta: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta || {},
    alias: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.alias || [],
    redirect: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.path ?? "/pt/spas/gran-canaria/mogan/",
    props: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.props ?? false,
    meta: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta || {},
    alias: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.alias || [],
    redirect: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.path ?? "/pt/spas/gran-canaria/san-agustin-maspalomas/",
    props: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.props ?? false,
    meta: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta || {},
    alias: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.alias || [],
    redirect: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.path ?? "/pt/spas/gran-canaria/san-bartolome-de-tirajana/",
    props: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.props ?? false,
    meta: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta || {},
    alias: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.alias || [],
    redirect: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.path ?? "/pt/spas/gran-canaria/telde/",
    props: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.props ?? false,
    meta: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta || {},
    alias: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.alias || [],
    redirect: SpaList_46pagepo_45vIbRy6dN8cCxQmYeJQ26ibj54QA7XCiO9jL4FJDkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/",
    props: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.props ?? false,
    meta: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta || {},
    alias: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.alias || [],
    redirect: SpaDetail_46pagewibZCW78ZbnWmw4o_pXA_hCilVYm5sXJrtBPfcoM37cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/terapia-da-madeira/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/massagem-as-costas-e-circuito-de-spa-para-duas-pessoas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/circuito-termal-para-dois-2-horas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/circuito-de-spa-1-hora/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/massagem-desportiva/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/massagem-com-pedras-vulcanicas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/massagem-dorsal-e-circuito-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/reflexologia-dos-pes-circuito-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/massagem-anti-stress-circuito-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/circuito-massagem-oriental-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/massagem-aromatica-circuito-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/tratamento-anti-envelhecimento-kobido-circuito-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/pacote-de-beleza-para-maos-e-pes-degustacao-de-cha/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/pacote-romantico-massagem-spa-almoco/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/massagem-relaxante-para-dois-com-cava-de-chocolate-circuito-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/circuito-de-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/massagem-wellness-circuito-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/ritual-indiano-e-spa-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/experiencia-relaxante-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/citrinos-de-bem-estar-relaxantes-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/spa-privado-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/spa-privado-com-massagem-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/momento-spa-para-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/daypass-spa-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/bronzeado-rapido-e-de-longa-duracao/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/noite-doce-no-be-aloe/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-nas-canarias/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/experiencia-nocturna/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/tratamento-lifting-vitamina-c/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/terapia-do-chocolate/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/be-aloe-massagem/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/serenity-eco-massagem-anti-stress-com-oleo-100-natural/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-dia-do-pai/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-nas-canarias-ilhas-canarias-casal/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/aloe-piscina-e-jantar/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/be-aloe-pool-food/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/experiencia-sensorial-sublime-e-personalizada/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/circuito-de-bem-estar-be-aloe/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/voucher-be-aloe-welness-para-duas-pessoas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-da-futura-mama/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/be-aloe-wellness-yoga-e-pequeno-almoco/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-150e/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/massagem-de-costas-pescoco-e-ombros/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-100-euros/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-200-euros/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-300-euros/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-50-euros/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/circuito-spa-massagem-50-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/spa-romantico-privado-para-2-pessoas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/ritual-do-parque-cristobal/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/massagem-a-sua-escolha-50-min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/massagem-de-relaxamento-para-as-futuras-mamas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/massagem-jelly-beans-para-raparigas-e-rapazes/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/pacote-o-meu-pai-e-eu/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/pacote-relax-spa-e-massagem-nas-costas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/circuito-de-spa-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/experiencia-spa-para-criancas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/pacote-a-minha-mama-e-eu/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/circuito-de-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/manicura-com-goma-laca/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/daypass-circuito-termal-almoco-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/equilibrio-facial/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/segredo-de-santa-agueda/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/circuito-spa-massagem-40-minutos/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/circuito-termal-ritual-reino-da-hungria/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/reino-da-hungria-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/cordial-anti-stress/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/relaxante-completo-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/relaxante-parcial/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/circuito-spa-tratamento-facial-aquatico/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/circuito-de-entrada-externo/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/magia-de-perchel-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/daypass-circuito-termal-almoco/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/sonho-de-callao-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/massagem-com-pedras-quentes-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/massagem-balinesa-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/terapeutico-completo-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/terapeutica-parcial/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/buffet-de-spa-sem-pressa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-cava-x-2-all-bubbles/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/aloe-spa-pele-saudavel/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-terra-wellness/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/dia-de-luxo-your-spa-daypass/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-moet-x-2-un-capricho/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/tapas-spa-pequenos-sabores/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-de-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-spa-gastronomia-massagem-cocktail/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-de-spa-massagem-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-de-spa-massagem/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-de-spa-para-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-termal-gastronomia-cocktail/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-ligeira-das-pernas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/aromaterapia-80/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/pacote-anti-stress/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-sueca/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/aromaterapia-50/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/experiencia-de-spa-de-4-horas-pacote-gourmet/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-dois-a-dois/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-para-as-futuras-mamas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/envolvimento-corporal-com-aloe-vera/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/envolvimento-com-lama-do-mar-morto/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-chirogolf/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/capricho-de-chocolate/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-com-pedras/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/4-horas-de-experiencia-de-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/pacote-de-beleza/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/pacote-de-experiencia-de-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-completa-com-aromaterapia-duplo/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/oasis-of-serenity-duplo/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-parcial-relaxante-duplo/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/oasis-de-serenidade/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/especial-reino-da-hungria-duplo/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/reflexologia-50-min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/circuito-de-spa-externo/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/circuito-spa-hydra-facial/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/circuito-spa-peeling-corporal-ritual-sul-das-ilhas-canarias/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-completa-com-aromaterapia-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/especial-do-reino-da-hungria/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/ritual-do-reino-da-hungria-2-horas-de-circuito-termal/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/equilibrio-facial/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-parcial-relaxante/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-relaxante-de-corpo-inteiro/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-facial-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/ritual-oriental-balines-2-horas-de-circuito-de-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/pedras-quentes/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-balinesa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/shiatsu/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-ao-pescoco-2-horas-de-circuito-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/ritual-do-reino-do-egito-2-horas-de-circuito-termal/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/pack-seaweed-adelgacante-corporal-e-remineralizante-facial/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/menu-om-in-love-sobremesacava/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-dois-a-dois/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/noite-romantica-e-experiencia-thalasso-4-horas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/aromaterapia-50min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/por-do-sol-vista-para-o-mar-x2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/ocean-sunset-vista-para-o-mar/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/experiencia-thalasso-3h-menu-canto-da-natureza/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/experiencia-thalasso-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/por-do-sol-no-oceano-x2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/por-do-sol-no-oceano-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/experiencia-thalasso-1-dia-menu-canto-da-natureza/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/experiencia-thalasso-3h-menu-canto-da-natureza-x-2/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-para-as-futuras-mamas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/capricho-de-chocolate/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/piscina-de-hidroterapia-ocean-view-30min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/paraiso-oceanico-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-anti-stress/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-com-pedras/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/rassoul/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-de-tecidos-profundos/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/evasao-e-beleza-com-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/a-fonte-do-bem-estar-com-o-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/vitalidade-absoluta-com-o-spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/spa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/circuito-das-termas-de-inagua-hospedes-cordiais/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/ritual-pre-solar/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/reino-do-egito/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/oasis-de-serenidade/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/circuito-das-termas-de-inagua/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/maes-expectantes/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/fruta-embrulhada/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/especial-do-reino-da-hungria/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/tratamento-facial-sea-sensation/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-shiatsu/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-tailandesa/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/costas-e-pernas-terapeutico/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-ayurveda/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/reflexologia-55min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/peeling-corporal-com-sais-do-mar-morto/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-de-corpo-inteiro/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-50-euros/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-150e/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-100-euros/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-200-euros/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-300-euros/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/spa-no-radisson-blu-resort-75-min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/spa-e-massagem-privados/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/contractura-muscular-localizada/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-aromasoul-aromaterapia-50min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/spa-romance-para-dois/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-para-as-futuras-mamas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/mi-mama-y-yo-especial-para-raparigas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/ritual-das-canarias-80-min-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/envolvimento-de-aloe-vera-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/pacote-para-dois-1/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-com-ursinhos-de-goma/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/o-meu-pai-e-eu-especial-para-criancas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-as-costas/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-a-sua-escolha-50min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-ayurveda/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-lomi-lomi/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/pacote-experiencia-aromaterapia/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/dia-de-gloria/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-50-min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/momentos-romanticos/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-50-min-casais/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-ao-ar-livre-50-min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/experiencia-de-harmonia/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/pacote-especial-essencial/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-de-relaxamento-25-min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-de-relaxamento-25-min-casais/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/ayurveda-casais/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/ayurveda/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/pacote-especial-thalasso/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/niagara/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/vouchers-de-massagem-relaxante-25-min-10sessoes/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/vale-de-massagem-50-min-10-sessoes/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/vale-de-fangoterapia-10-sessoes/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/tratamento-hyalu-procollagen/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/tratamento-nu-skin/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/pacote-especial-essencial/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/descasque-de-cristais-com-flores/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/um-dia-na-gloria/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-ao-ar-livre/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-50-min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/especial-dia-dos-namorados/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-50-min-casais/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/piscina-fitness/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/momentos-romanticos/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/experiencia-thalasso/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/piscina-fitness-para-casais/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/terapia-de-lama-25-min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/thalgo-relax/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-de-relaxamento-25-min/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-de-relaxamento-25-min-casais/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/ayurveda/",
    props: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.props ?? false,
    meta: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta || {},
    alias: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.alias || [],
    redirect: ServiceDetail_46pageNnJDU9tRydBviUuoKy7CoWht6tFp48VYhQ4d6gbwRYAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-62e5209b-92e7-4584-a168-1a8b5be1b902/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]